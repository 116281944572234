import React, { Component, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CountdownTimer from "./countdown-timer";

import SbsLogo from "../assets/images/sbs-logo.png";
import NcrBannerLogo from "../assets/images/logo/ncr-banner-logo.png";
import bglogo from "../assets/images/ncr-banner-logo.png";
// import banner from "/assets/images/home-banner-bg_new.jpg";
import MobileBanner from "../assets/images/mobile_img.jpg";
import DesktopBanner from "../assets/images/home-banner-bg_new.jpg";
import { Link } from "react-router-dom";
import Login from "./LoginModal/Login";
// const bglogo = "https://d7le4ielk3ho1.cloudfront.net/src_images/logo/rugby-white.png";

class homepageBanner extends Component {
  constructor(props) {
    var today = new Date(),
      time = today.toISOString();
    super(props);
    this.state = {
      currentTime: time,
      tournamenttime: localStorage.getItem("SatrtTime"),
      localtoken: localStorage.getItem("login"),
      show: false,
    };
  }
  handleShow = () => this.setState({show:true})
 loginKey = localStorage.getItem("login");
 handleNavigation = ()=>{
  if (this.loginKey) {
    // this.props.history.push("/create-brackets")
    window.location.href = "/create-brackets"
  }
  else{
    this.handleShow()
  }
 }
  render() {

    return (
      <>
        <Col>
        <button
        style={{ display:"flex", width:"25%", justifyContent:"center", alignItems:"center", margin:"auto", marginTop:"20px", marginBottom:"20px", fontWeight:"bold", fontSize:"16px", padding:"10px", fontFamily:"Roboto Condensed, sans-serif", }}
        // to="/create-brackets"
        onClick={()=>{this.handleNavigation()}}
        className="btn btn-livescore 123 bracket-btn"
        >
        Join the Bracket Challenge !
        </button>
         {this.state.show && (
                        <Login
                          show={this.state.show}
                          onClick={() => this.setState({show:false})}
                          // onClickB={this.setAddClassb}
                          redirectUrl={"/create-brackets"}
                        />
                      )}
          <div className="hero-box">
            <div className="bgimage">
              <div className="banner-overlay"></div>
              <div className="container-fluid">
                {/* <div className="logo">
                  <img src={bglogo} alt="deafult" />
                </div> */}
                <div className="dt-banner">
                  <img
                    width={"100%"}
                    // src={"/assets/images/home-banner-bg_new.jpg"}
                    src={DesktopBanner}
                    alt="deafult"
                  />
                </div>
                <div className="mb-banner">
                  <img
                    width={"100%"}
                    // src={"/assets/images/mobile_img.jpg"}
                    src={MobileBanner}
                    alt="deafult"
                  />
                </div>
                <Row>
                  <Col>
                    <div className="content">
                      {/* <div className="sbs-logo-banner"><img src={NcrBannerLogo} alt="deafult" /></div> */}
                      {/* <h1>Build Your Bracket and Win Prizes Championship Tournament</h1>
                      <h3>New Orleans • May 28 - 30, 2022</h3>
                      <h3>Brackets close in</h3> */}
                      {this.state.tournamenttime == "true" &&
                      this.state.localtoken == null ? (
                        <></>
                      ) : (
                        <>{/* <h4>TIME REMAINING</h4> */}</>
                      )}

                      <CountdownTimer />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </>
    );
  }
}

export default homepageBanner;
